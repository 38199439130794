import React, { FC, useCallback } from "react";
import { NotFound, NotFoundProps } from "../components/EmptyState/NotFound";

export const NotFoundContainer: FC = () => {
  const action = useCallback(() => {
    window.location.href = "/";
  }, []);

  const props: NotFoundProps = {
    action,
  };

  return <NotFound {...props} />;
};
