import { Alert, Box, Snackbar } from "@mui/material";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { FC, useEffect, useState } from "react";
import { usePortalProvider } from "../../providers/portal-provider";
import { NotAuthorizedContainer } from "../../containers/NotAuthorizedContainer";

interface ToastOpen {
  open: boolean;
  roles: string[];
  nome: string;
}

export const OpalaAlertContainer: FC = () => {
  const [openToast, setOpenToast] = useState(true);
  const { profile, setProfile } = usePortalProvider();
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!keycloak?.authenticated) {
      return;
    }

    keycloak.loadUserInfo().then((profile) => {
      setProfile(profile);
    });
  }, [keycloak, initialized, setProfile]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  const getFirstName = (fullName: string) => {
    if (!fullName) return "";
    return fullName.split(" ")[0];
  };

  const props: ToastOpen = {
    ...profile,
    open: openToast,
    roles: profile?.roles as string[],
    nome: getFirstName(profile?.name as string),
  };

  const rolePriorities = ["opala", "ouro", "prata", "bronze"];
  const userRole = props.roles
    ? rolePriorities.find((role) => props.roles?.includes(role))
    : undefined;

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        autoHideDuration={30000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {props.nome}, seu nível ({userRole}) está abaixo do requerido para
          acessar esse serviço.
        </Alert>
      </Snackbar>
      <NotAuthorizedContainer />
    </Box>
  );
};
