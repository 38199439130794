import React, { FC, useCallback } from "react";
import {
  GenericError,
  GenericErrorProps,
} from "../components/EmptyState/GenericError";
export const GenericErrorContainer: FC = () => {
  const action = useCallback(() => {
    window.location.href = "/";
  }, []);

  const props: GenericErrorProps = {
    action,
  };

  return <GenericError {...props} />;
};
