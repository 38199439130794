import { DependencyList, useEffect } from "react";
import { TagObject } from "../shared-types";
import MurmurHashV3 from "imurmurhash";

function generateTagId(tag: TagObject): number {
  const hash = MurmurHashV3(tag.content || "");
  Object.keys(tag.attributes).forEach((key) =>
    hash.hash(`${key}${tag.attributes[key]}`)
  );

  return hash.result();
}

export function useDomElement(
  tagName: string,
  tagObjects: TagObject[],
  target: HTMLHeadElement | HTMLBodyElement,
  ignoreRender: boolean,
  deps: DependencyList
): void {
  useEffect(() => {
    if (typeof target === "undefined") {
      return;
    }

    if (!target || ignoreRender) {
      return;
    }
    const items: HTMLElement[] = [];
    tagObjects.forEach((tagObject) => {
      const id = generateTagId(tagObject);
      let serverRenderedElement: HTMLElement | null = target.querySelector(
        `[data-portal-id='${id}']`
      );

      if (serverRenderedElement) {
        items.push(serverRenderedElement);
        return;
      }

      const el = document.createElement(tagName);

      Object.keys(tagObject.attributes as Record<string, string>).forEach(
        (key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          el[key] = tagObject.attributes[key];
        }
      );
      el.innerHTML = tagObject.content || "";

      target.appendChild(el);
      items.push(el);
    });

    return () => {
      items.forEach((el) => target.removeChild(el));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagObjects, tagName, target, ...deps]);
}
