import React, { FC, useCallback } from "react";
import {
  NotAuthorized,
  NotAuthorizedProps,
} from "../components/EmptyState/NotAuthorized";

export const NotAuthorizedContainer: FC = () => {
  const action = useCallback(() => {
    window.location.href = "/";
  }, []);

  const props: NotAuthorizedProps = {
    action,
  };

  return <NotAuthorized {...props} />;
};
