import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { Info, Subtitle, Title } from "./styles";

export interface NotAuthorizedProps {
  action: MouseEventHandler<HTMLButtonElement>;
}

export const NotAuthorized: FC<NotAuthorizedProps> = ({ action }) => {
  const [mobileOS, setMobileOS] = useState("");

  function getMobileOS() {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "https://play.google.com/store/apps/details?id=br.gov.pi.pidigital&pli=1";
    } else if (
      /iPad|iPhone|iPod/.test(ua) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      return "https://apps.apple.com/in/app/gov-pi-cidad%C3%A3o/id6459582210";
    }
    return "https://play.google.com/store/apps/details?id=br.gov.pi.pidigital&pli=1";
  }

  useEffect(() => {
    const osLink = getMobileOS();
    setMobileOS(osLink);
  }, []);

  return (
    <div>
      <Container>
        <Box display={"flex"} mt={5}>
          <Info fontSize="large" />
          <Title ml={3}>Como aumentar o seu nível</Title>
        </Box>
        <Subtitle mt={3}>Para obter o nível Bronze, Prata ou Ouro:</Subtitle>
        <ul>
          <li style={{ marginBottom: 20 }}>
            Ao acessar a tela de login do Gov.pi Cidadão e realizar login com a
            conta Gov.br, os níveis serão iguais.
          </li>
          <li>
            Caso no Gov.br o seu nível seja Bronze, seu nível no Gov.pi será
            Bronze, e assim por diante até chegar o nível máximo que é o nível
            Ouro.
          </li>
        </ul>
        <Typography mt={3}>
          Caso deseje elevar o seu nível atual, siga os requesitos necessários
          disponíveis no{" "}
          <a
            href="https://www.gov.br/governodigital/pt-br/identidade/conta-gov-br/niveis-da-conta-govbr/saiba-mais-sobre-os-niveis-da-conta-govbr"
            style={{
              color: "#034EA2",
              fontWeight: 500,
              textDecoration: "none",
            }}
          >
            Gov.br
          </a>
          .
        </Typography>
        <Subtitle mt={3} mb={3}>
          Para obter o nível Opala (Necessário ter nível Ouro):
        </Subtitle>
        <ol>
          <li style={{ marginBottom: 20 }}>
            Baixe o Applicativo{" "}
            <a
              href={mobileOS}
              style={{
                color: "#263238",
                fontWeight: 700,
                textDecoration: "none",
              }}
            >
              GOV.PI Cidadão
            </a>
            , em sua loja de aplicativos;
          </li>
          <li style={{ marginBottom: 20 }}>
            Faça o login com sua conta do GOV.BR;
          </li>
          <li style={{ marginBottom: 20 }}>
            Ao acessar a tela inicial faça a atualização do seu perfil, clicando
            no card de atualização de nível para Opala;
          </li>
          <li style={{ marginBottom: 20 }}>Faça o reconhecimento facial; </li>
          <li style={{ marginBottom: 20 }}>
            Seu nível será atualizado para Opala.
          </li>
        </ol>
        <Box display={"flex"} mt={5}>
          <Button variant="contained" onClick={action}>
            Voltar para a página inicial
          </Button>
        </Box>
      </Container>
    </div>
  );
};
