import React, { FC, useCallback, useEffect } from "react";
import { ISideBarProps, SideBar } from "./Sidebar";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { usePortalProvider } from "../../providers/portal-provider";
import { usePortalAuth } from "../../providers/keycloak-provider";

export const SidebarContainer: FC<ISideBarProps> = ({
  searchElement,
  isExpanded,
}) => {
  const { ssr, profile, setProfile } = usePortalProvider();
  const { logout } = usePortalAuth();

  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  const onLogout = useCallback(() => {
    if (ssr) {
      console.log("ssr");
    }
    console.log("no-ssr");

    if (ssr) {
      logout();
      return;
    }

    // keycloak?.logout();
  }, [logout, keycloak, ssr]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!keycloak?.authenticated) {
      return;
    }

    keycloak.loadUserInfo().then((profile) => {
      setProfile(profile);
    });
  }, [keycloak, initialized, setProfile]);

  const props: ISideBarProps = {
    ...profile,
    roles: profile?.roles as string[],
    nome: profile?.name as string,
    cpf: profile?.preferred_username as string,
    searchElement: searchElement,
    onLogout,
    isExpanded: isExpanded,
  };

  if (!profile) return null;

  return <SideBar {...props} />;
};
