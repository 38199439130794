import { createTheme, alpha } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export const Colors = {
  AZUL_GOV: "#034EA2",
  AMARELO_GOV: "#FDB913",
  VERMELHO_GOV: "#EF4123",
  VERDE_GOV: "#007932",
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1367,
      xl: 1700,
    },
  },
  palette: {
    primary: {
      main: Colors.AZUL_GOV,
      contrastText: alpha("#FAFAFA", 0.84),
      light: "#2773C8",
    },
    secondary: {
      main: Colors.AMARELO_GOV,
      contrastText: "#181818",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          borderRadius: "14px",
          background: "#FFFFFF",
          borderWidth: 1,
          borderColor: "#ECEEF4",
          boxShadow: "none",
        },
      },
    },
  },
});
