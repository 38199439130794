import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "@mui/material";
import React, { FC } from "react";
import { ExternalLink } from "../Link/ExternalLink";

interface TopBarProps {
  openSideBarIcon: React.ReactElement;
}

export const TopBar: FC<TopBarProps> = ({ openSideBarIcon }) => {
  return (
    <div className="xvia-header-topbar">
      <Container className="xvia-content-section xvia-header-section">
        <div className="xvia-header-info">
          <ExternalLink link="https://transparencia.pi.gov.br/">
            Portal da transparência
          </ExternalLink>

          <ExternalLink link="https://www.pi.gov.br">Governo</ExternalLink>

          <ExternalLink link="https://www.pi.gov.br">
            Mais Sites <FontAwesomeIcon icon={faChevronDown} />
          </ExternalLink>
        </div>
        <div className="xvia-header-quick-links hidden">
          <ExternalLink link="https://www.pi.gov.br">
            Acessibilidade
          </ExternalLink>

          <ExternalLink link="https://www.pi.gov.br">Mapa do site</ExternalLink>
        </div>
      </Container>

      {openSideBarIcon}
    </div>
  );
};
