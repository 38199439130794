import { Typography, styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const Info = styled(ErrorOutlineIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;

  margin-bottom: 0.3rem;
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  font-weight: 500;
`;
