import React, { FC, MouseEventHandler } from "react";
import { Result } from "antd";
import { Button } from "@mui/material";

export interface GenericErrorProps {
  action: MouseEventHandler<HTMLButtonElement>;
}

export const GenericError: FC<GenericErrorProps> = ({ action }) => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Desculpe, A aplicação que você está tentando carregar está temporariamente indisponível."
      extra={
        <Button variant="contained" onClick={action}>
          Voltar para a página inicial
        </Button>
      }
    />
  );
};
