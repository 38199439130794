import React from "react";
import { Header, HeaderProps } from "./Header";
import { usePortalProvider } from "../../providers/portal-provider";

export const HeaderContainer = (props: HeaderProps) => {
  const { noHeader } = usePortalProvider();

  if (noHeader) return null;

  return React.createElement(Header, props);
};
