import React, { FC, useEffect, useState } from "react";
import { AppResponse } from "../../shared-types";
import { useLocation } from "react-router-dom";

export interface AppIframeLauncherProps {
  app: AppResponse;
}

function calculateIframeUrl(
  app: AppResponse,
  pathname: string,
  basePath: string
) {
  const sanitizedPath = pathname
    .replace(basePath, "")
    .replace(app.slug, "")
    .split("/")
    .filter((path) => !!path)
    .join("/");

  const appUrl = app.url.replace(/\/$/, "");

  return `${appUrl}/${sanitizedPath}`;
}

export const AppIframeLauncher: FC<AppIframeLauncherProps> = ({ app }) => {
  const { pathname } = useLocation();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    setUrl(calculateIframeUrl(app, pathname, APP_BASE_PATH));
  }, [app, pathname]);

  if (!url) {
    return null;
  }

  return (
    <iframe
      id={app.slug}
      title={app.slug}
      className="xvia-content-frame"
      src={url}
    />
  );
};
