import { Checklist, Home, Output, Window } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { IAppLoader } from "../../apis/IAppLoader";
import "./SideBar.css";

export interface ISideBarProps extends IAppLoader {
  roles: string[];
  searchElement: React.ReactElement;
  isExpanded: boolean;
  onLogout?: MouseEventHandler<HTMLLIElement>;
}

export const SideBar: FC<ISideBarProps> = ({
  nome,
  cpf,
  roles,
  validations,
  searchElement,
  isExpanded,
  onLogout,
}) => {
  const publicUrl = process.env.PUBLIC_URL || "";
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const mascaraCPF = (cpf: any) => {
    return `CPF: ***.***.${cpf?.slice(-2)}`;
  };

  const menuItems = [
    {
      icon: <Home />,
      text: "Painel de Serviços",
      href: `${publicUrl}/app/dashboard`,
    },
    {
      icon: <Window />,
      text: "Carta de Serviços",
      href: `${publicUrl}/app/catalog/list`,
    },
    {
      icon: <Checklist />,
      text: "Minhas Solicitações",
      href: `${publicUrl}/app/acompanhar-minhas-solicitacoes`,
    },
  ];

  const stampItems = [
    {
      text: "Cadastro básico",
      alt: "Validação com os dados básicos de cadastro.",
      image: "cadastro",
    },
    {
      text: "Validação do INSS",
      alt: "Validação com as perguntas do INSS.",
      image: "previdencia",
    },
    {
      text: "Validação da Previdência",
      alt: "Validação na Previdência.",
      image: "previdencia",
    },
  ];

  const rolePriorities = ["opala", "ouro", "prata", "bronze"];
  const userRole = roles
    ? rolePriorities.find((role) => roles.includes(role))
    : undefined;
  const userRoleText = userRole
    ? `Sua conta é nível ${userRole}`
    : "Valide o seu Nível em Perfil";

  useEffect(() => {
    const currentPath = window.location.pathname;

    const initialSelectedIndex = menuItems.findIndex((item) =>
      currentPath.startsWith(item.href)
    );
    setSelectedIndex(initialSelectedIndex);
    if (cpf) {
      setTimeout(() => setIsLoading(false), 2000);
    }
  }, [cpf]);

  const handleClick = (index: number, href: string) => {
    setSelectedIndex(index);
    window.open(href, "_self");
  };

  return (
    <Paper
      className="xvia-dashboard-side-bar__wrapp"
      sx={(theme) => ({
        backgroundColor: "#034EA2",
        borderRadius: 0,
        minHeight: "100vh",
        color: theme.palette.primary.contrastText,
        maxWidth: 358,
        padding: theme.spacing(5, 4, 4, 4),

        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(4, 4, 4, 4),
        },
        [theme.breakpoints.down(992)]: {
          opacity: isExpanded ? 100 : 0,
          display: isExpanded ? "block" : "none",
          zIndex: isExpanded ? "10100" : undefined,
          position: isExpanded ? "absolute" : "initial",

          overflowY: "auto",
          height: "100vh",

          transition: "maxWidth 1s ease-out",
        },
      })}
    >
      <Stack
        sx={(theme) => ({
          mb: theme.spacing(5),
        })}
      >
        <a href="/">
          <picture>
            <source
              srcSet={`${publicUrl}/assets/img/logo/logo-mobile.svg`}
              media="(max-width: 991px)"
              style={{ height: "56px", filter: "brightness(0) invert(1)" }}
            />
            <img
              src={`${publicUrl}/assets/img/logo/logo.svg`}
              alt="Governo do Estado do Piauí"
              style={{ height: "56px", filter: "brightness(0) invert(1)" }}
            />
          </picture>
        </a>
      </Stack>
      <Stack>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={(theme) => ({
              width: theme.spacing(8),
              height: theme.spacing(8),
              [theme.breakpoints.down("lg")]: {
                width: theme.spacing(6),
                height: theme.spacing(6),
              },
            })}
          />
          <Typography
            sx={(theme) => ({
              ml: theme.spacing(2),
              textTransform: "uppercase",
              textWrap: "wrap",
              fontWeight: 700,
              width: 150,
              [theme.breakpoints.down("lg")]: {
                fontSize: "0.875rem",
              },
            })}
          >
            {nome}
          </Typography>
        </Box>
        <Typography
          sx={(theme) => ({
            fontWeight: 500,
            my: theme.spacing(2),
          })}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              sx={{ bgcolor: "rgba(255, 255, 255, 0.5)" }}
              variant="rounded"
              width={110}
              height={24}
            />
          ) : (
            mascaraCPF(cpf)
          )}
        </Typography>
        <Chip
          label={userRoleText}
          sx={(theme) => ({
            width: "fit-content",
            backgroundColor: "#FDB913",
            color: "#181818",
            fontWeight: 700,

            "& .MuiChip-label": {
              px: theme.spacing(3),
            },
          })}
        />
      </Stack>
      <MenuList
        sx={(theme) => ({
          my: theme.spacing(1),
          borderColor: theme.palette.primary.light,
          [theme.breakpoints.down("lg")]: {
            my: theme.spacing(1),
          },
        })}
      ></MenuList>
      <MenuList
        sx={(theme) => ({
          mt: theme.spacing(0),
          mb: theme.spacing(4),
          p: theme.spacing(0),
        })}
      >
        <MenuItem
          sx={(theme) => ({
            p: theme.spacing(0),
          })}
        >
          {searchElement}
        </MenuItem>
      </MenuList>
      <MenuList
        sx={(theme) => ({
          padding: 0,
          "& .MuiMenuItem-root": {
            "& ~ .MuiMenuItem-root": {
              marginTop: theme.spacing(2.25),
            },
          },
          [theme.breakpoints.down("lg")]: {
            "& .MuiMenuItem-root": {
              "& ~ .MuiMenuItem-root": {
                marginTop: theme.spacing(1.25),
              },
            },
          },
        })}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            onClick={() => handleClick(index, item.href)}
            key={index}
            sx={(theme) => ({
              backgroundColor:
                selectedIndex === index ? "#164194" : "transparent",
              borderRadius: selectedIndex === index ? "30px" : "0px",
              pointerEvents: selectedIndex === index ? "none" : "auto",
              "&:hover": {
                backgroundColor:
                  selectedIndex === index
                    ? "darkgray"
                    : theme.palette.action.hover,
              },
            })}
          >
            <ListItemIcon
              sx={(theme) => ({
                color: "white",
                "& svg": { width: 22, height: 22 },
                [theme.breakpoints.down("lg")]: {
                  "& svg": { width: 18, height: 18 },
                },
              })}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              sx={(theme) => ({
                color: theme.palette.primary.contrastText,
                "& .MuiTypography-root": {
                  fontSize: "0.9375rem",
                  lineHeight: "8px",
                },
              })}
            >
              {item.text}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
      <Stack
        sx={(theme) => ({
          borderTop: 1,
          borderColor: theme.palette.primary.light,
          mt: theme.spacing(4),
          ml: theme.spacing(2),
          [theme.breakpoints.down("lg")]: {
            mt: theme.spacing(2),
            ml: theme.spacing(1),
          },
        })}
      >
        <Typography
          sx={(theme) => ({
            fontSize: "0.875rem",
            color: "white",
            mt: theme.spacing(3),
            [theme.breakpoints.down("lg")]: {
              mt: theme.spacing(1),
            },
          })}
        >
          Selos de confiabilidade
        </Typography>
        <Box
          sx={(theme) => ({
            mt: theme.spacing(4),
            "& .MuiBox-root": {
              "& ~ .MuiBox-root": {
                mt: theme.spacing(1),
              },
            },
            [theme.breakpoints.down("lg")]: {
              mt: theme.spacing(2),
            },
          })}
        >
          {stampItems.map((item, index) => (
            <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
              <Avatar
                alt={item.alt}
                src={`/assets/selos/${item.image}.png`}
                sx={(theme) => ({
                  width: theme.spacing(4),
                  height: theme.spacing(4),
                  backgroundColor: "#C7D7EA",
                  borderRadius: theme.spacing(4),
                  [theme.breakpoints.down("lg")]: {
                    width: theme.spacing(3),
                    height: theme.spacing(3),
                  },
                })}
              />
              <Typography
                sx={(theme) => ({
                  ml: theme.spacing(2),
                  fontSize: "0.875rem",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "0.75rem",
                  },
                })}
              >
                {item.text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={(theme) => ({
            mt: theme.spacing(6),
            display: "flex",
            width: "100%",
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
              mt: theme.spacing(4),
            },
          })}
        >
          <MenuItem
            onClick={onLogout}
            sx={(theme) => ({
              p: theme.spacing(0),
              [theme.breakpoints.down(992)]: {
                marginBottom: 15,
              },
            })}
          >
            <ListItemIcon
              sx={(theme) => ({
                color: "white",
                "& svg": { width: 22, height: 22 },
                [theme.breakpoints.down("lg")]: {
                  "& svg": { width: 18, height: 18 },
                },
              })}
            >
              <Output />
            </ListItemIcon>
            <ListItemText
              sx={(theme) => ({
                color: theme.palette.primary.contrastText,
                "& .MuiTypography-root": {
                  fontSize: "0.9375rem",
                  lineHeight: "8px",
                },
              })}
            >
              Sair
            </ListItemText>
          </MenuItem>
          <Typography
            className="hidden"
            sx={(theme) => ({
              color: "white",
              fontSize: "0.875rem",
              [theme.breakpoints.down("lg")]: {
                fontSize: "0.75rem",
              },
            })}
          >
            Ajuda
          </Typography>
          <Typography
            className="hidden"
            sx={(theme) => ({
              color: "white",
              ml: theme.spacing(6),
              fontSize: "0.875rem",
              [theme.breakpoints.down("lg")]: {
                fontSize: "0.75rem",
              },
            })}
          >
            Chat
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};
