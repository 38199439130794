import React, { useCallback, KeyboardEvent } from "react";
import { InputBase } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

export interface SearchBarProps {
  className?: string;
  onTextChange?: (text: string) => void;
  onGroupChange?: (key: string) => void;
  placeholderText?: string;
  onClickSearch?: () => void;
  selectedGroup?: string;
  groups?: { key: string; value: string }[];
}

export const SearchBar = ({
  className,
  onTextChange,
  onClickSearch,
  selectedGroup,
  onGroupChange,
  placeholderText,
  groups,
}: SearchBarProps) => {
  const baseClassName = cx("xvia-search-bar", className);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onTextChange && onTextChange(event.target.value);
    },
    [onTextChange]
  );

  const onKeyDownEventHandler = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onClickSearch && onClickSearch();
      }
    },
    [onClickSearch]
  );

  return (
    <div className={baseClassName}>
      <InputBase
        className="xvia-search-bar__input"
        sx={{
          ml: 1,
          flex: 1,
          input: {
            "&::placeholder": {
              fontSize: "12.5px",
              opacity: 1,
            },
          },
        }}
        placeholder={placeholderText}
        inputProps={{ "aria-label": placeholderText }}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownEventHandler}
      />
      <button className={"xvia-search-bar__btn-icon"} onClick={onClickSearch}>
        <FontAwesomeIcon icon={faSearch} />{" "}
      </button>
    </div>
  );
};
