import React, { FC, MouseEventHandler } from "react";
import { Result } from "antd";
import { Button } from "@mui/material";

export interface NotFoundProps {
  action: MouseEventHandler<HTMLButtonElement>;
}

export const NotFound: FC<NotFoundProps> = ({ action }) => {
  return (
    <Result
      status="404"
      title="Página não encontrada"
      subTitle="O conteúdo que você procura não foi encontrado em nossos
              servidores."
      extra={
        <Button variant="contained" onClick={action}>
          Voltar para a página inicial
        </Button>
      }
    />
  );
};
