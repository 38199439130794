import React from "react";
import { TopBar } from "./TopBar";

export interface HeaderProps {
  rightElement?: React.ReactElement;
  centerElement?: React.ReactElement;
  openSideBarIcon: React.ReactElement;
}

export const Header = (props: HeaderProps) => (
  <header className="xvia-header-area">
    <TopBar openSideBarIcon={props.openSideBarIcon} />
  </header>
);
