import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

interface OpenSideBarButtonProps {
  handlerOpenCloseSideBar: () => void;
  sideBarIsExpandend: boolean;
}

export const OpenSideBarButton = ({
  handlerOpenCloseSideBar,
  sideBarIsExpandend,
}: OpenSideBarButtonProps) => {
  return (
    <IconButton
      onClick={handlerOpenCloseSideBar}
      sx={(theme) => ({
        display: "none",
        [theme.breakpoints.down(992)]: {
          display: "initial",
          alignItems: "flex-start",
          borderRadius: 0,
          backgroundColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        },
      })}
    >
      {sideBarIsExpandend ? (
        <CloseIcon sx={{ color: "white" }} />
      ) : (
        <MenuIcon sx={{ color: "white" }} />
      )}
    </IconButton>
  );
};
